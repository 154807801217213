import styled from '@emotion/styled'
import type { ButtonModifier } from '@polycups/react'
import cx from 'classnames'

import { ButtonLink } from '@/legacy/components/Button'
import { Container, type BackgroundColor, type Space } from '@/legacy/components/Container'
import {
  BodyM,
  HeadingM,
  HeadingS,
  type TypographyColorModifier,
} from '@/legacy/components/Typography'
import { decodeURL } from '@/legacy/core/utils/dom/decodeURL'
import type { TitleProps } from '@/legacy/models'

import { builderLightText } from '../utils'

interface CtaButton {
  label: string
  link: string
  rounded: boolean
  modifier: ButtonModifier
}

export interface CtaBlockProps {
  id?: string
  title: TitleProps
  subtitle?: TitleProps
  background: BackgroundColor
  backgroundColor?: string
  withBorder?: boolean
  space: Space
  position: 'left' | 'center' | 'right'
  colorModifier?: TypographyColorModifier
  hasLargeTitle: boolean
  button: CtaButton
}

const CtaBlockStyles = styled(Container)<{ withBorder: boolean }>((props) => ({
  '& .content': {
    padding: '2rem 0',
  },
  '& .ecs-cta-t': {
    margin: '0 0 0.5rem',
  },
  '& .ecs-cta-subt': {
    margin: '0 0 1rem',
  },
  '& .ecs-cta-t + .ecs-cta-btn': {
    margin: '0.5rem 0 0',
  },
  '& .ecs-cta-btn': {
    width: '100%',
    maxWidth: '20.5rem',
  },
  ...(props.withBorder && {
    border: 'solid rgba(var(--theme-surface-lightgray))',
    borderWidth: '1px 0',
  }),
}))

const CtaBlock = ({
  id,
  title,
  subtitle,
  background,
  backgroundColor,
  space = 'm',
  position = 'center',
  hasLargeTitle,
  colorModifier,
  button: {
    label: buttonLabel,
    link: buttonLink,
    rounded: buttonRounded,
    modifier: buttonModifier,
  },
  withBorder = true,
}: CtaBlockProps) => {
  const lightText = builderLightText(colorModifier)
  const HeadingComponent = hasLargeTitle ? HeadingM : HeadingS

  return (
    <CtaBlockStyles
      id={id}
      className={cx({ [`et-ta-${position}`]: position })}
      withBorder={withBorder && !background.includes('gray')}
      backgroundColor={background}
      backgroundColorHex={backgroundColor}
      spaceOut={space}
      {...(hasLargeTitle && { spaceIn: 'l' })}
    >
      <div className="content">
        {title && title.text && (
          <HeadingComponent light={lightText} className="ecs-cta-t" tag={title.tag}>
            {title.text}
          </HeadingComponent>
        )}
        {subtitle && subtitle.text && (
          <BodyM light={lightText} dark="high" className="ecs-cta-subt" tag={subtitle.tag}>
            {subtitle.text}
          </BodyM>
        )}
        <ButtonLink
          source={decodeURL(buttonLink)}
          size="lg"
          modifier={buttonModifier}
          className="ecs-cta-btn"
          rounded={buttonRounded}
        >
          {buttonLabel}
        </ButtonLink>
      </div>
    </CtaBlockStyles>
  )
}

export default CtaBlock
